<template>
  <v-select
    v-model="program"
    v-bind="$attrs"
    :items="items"
    @change="userProgramChanged"
    item-value="user_program.id"
    return-object
  >
    <template v-slot:selection="data">
      {{ data.item.name }} ({{
        data.item.user_program.starts_at | formatedDate('ll')
      }}
      to
      {{ data.item.user_program.valid_until | formatedDate('ll') }})
    </template>
    <template v-slot:item="data">
      {{ data.item.name }} ({{
        data.item.user_program.starts_at | formatedDate('ll')
      }}
      to
      {{ data.item.user_program.valid_until | formatedDate('ll') }})
    </template>
  </v-select>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'UserProgramsSwitcher',

  props: {
    value: [String, Number, Object],
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      program: this.value,
    }
  },

  methods: {
    ...mapMutations({
      setUserSelectedProgram: 'users/setUserProgram',
    }),

    userProgramChanged(program) {
      this.setUserSelectedProgram(program)
      this.$emit('change', program.user_program.id)
    },
  },

  watch: {
    value(program) {
      this.program = program
    },
  },
}
</script>
