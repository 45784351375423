<template>
  <div class="user-profile">
    <main-app-bar :showSearchIcon="false">
      <template v-slot:title>
        <div v-if="user" class="d-flex align-center">
          <v-avatar class="my-3 mr-6" tile size="63px">
            <img :src="user.avatarThumbUrl" alt="John" />
          </v-avatar>
          <span>{{ user.full_name }}</span>
        </div>
      </template>

      <template v-slot:search>
        <user-program-switcher
          v-if="hasProgramSwitcher"
          v-model="currentProgramId"
          :items="programs"
          @change="fetchUserSelectedProgram"
          label="Select Program"
          hide-details
          outlined
          dense
        />
      </template>

      <template v-slot:actions>
        <div class="user-profile--nav-pills">
          <v-btn depressed :to="{ name: 'user.personal-information' }" replace>
            Personal Information
          </v-btn>

          <v-btn depressed :to="{ name: 'user.onboarding' }" replace>
            Onboarding
          </v-btn>

          <v-btn depressed :to="{ name: 'user.meal-plan' }" replace>
            Meal Plan
          </v-btn>

          <v-btn depressed :to="{ name: 'user.checkin' }" replace>
            Checkin
          </v-btn>

          <v-btn depressed :to="{ name: 'user.gallery' }" replace>
            Gallery
          </v-btn>

          <v-btn depressed :to="{ name: 'user.goal-history' }" replace>
            Goal History
          </v-btn>

          <v-btn depressed :to="{ name: 'user.meal-guide-history' }" replace>
            Meal Guide History
          </v-btn>

          <v-btn depressed :to="{ name: 'user.program-history' }" replace>
            Program History
          </v-btn>

          <v-btn depressed :to="{ name: 'user.membership-history' }" replace>
            Membership History
          </v-btn>
          <v-btn depressed :to="{ name: 'user.pause-user' }" replace>
            Pause History
          </v-btn>

          <v-btn depressed :to="{ name: 'user.program-revenue' }" replace>
            Revenue
          </v-btn>

          <v-btn depressed :to="{ name: 'user.off-boarding' }" replace>
            Offboarding
          </v-btn>
        </div>
      </template>
    </main-app-bar>

    <div class="px-12 py-6">
      <transition name="fade" mode="out-in">
        <router-view :loading="loading" />
      </transition>
    </div>
  </div>
</template>

<script>
import UserProgramSwitcher from '@/components/elements/programs/UserProgramsSwitcher'
import MainAppBar from '@/layouts/shared/MainAppBar'
import waterfall from 'async/waterfall'
import { mapActions, mapState } from 'vuex'
import Event from '@/services/eventBus'

export default {
  name: 'ProfilePage',

  components: {
    MainAppBar,
    UserProgramSwitcher,
  },

  data() {
    return {
      loading: true,
      hasPrograms: true,
      currentProgramId: null,
    }
  },

  async created() {
    await this.fetchUserDetails()
    this.currentProgramId = this.user.default_program.user_program.id
    this.fetchUserSelectedProgram()

    Event.$on('reload-user-profile', () => {
      waterfall([
        (cb) => {
          this.fetchUserDetails(cb)
        },
        (cb) => {
          this.fetchUserSelectedProgram(cb)
        },
      ])
    })
  },

  computed: {
    ...mapState({
      user: (state) => state.users.selectedUser,
      programs: (state) => state.users.allUserPrograms,
      selectedProgram: (state) => state.users.selectedUserProgram,
    }),

    hasProgramSwitcher() {
      return this.$route.meta.hasProgramSwitcher
    },
  },

  methods: {
    ...mapActions({
      getSelectedUserDetails: 'users/getSelectedUser',
      getAllUserPrograms: 'users/getAllUserPrograms',
      getSelectedUserProgram: 'users/getSelectedUserProgram',
    }),

    async fetchUserDetails() {
      const userId = this.$route.params.id

      this.loading = true

      await this.getSelectedUserDetails({ userId })
      await this.getAllUserPrograms(userId)

      this.loading = false
    },

    async fetchUserSelectedProgram(program = null) {
      const id = program ?? this.currentProgramId
      const userId = this.$route.params.id

      this.loading = true

      await this.getSelectedUserDetails({ userId, id })
      await this.getSelectedUserProgram({ userId, id })

      Event.$emit('reload-user-mealplan')

      this.loading = false
    },
  },

  watch: {
    selectedProgram(id) {
      this.currentProgramId = id
    },
  },
}
</script>
